import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, ref, string } from "yup";

const defaultValues = {
  id: null,
  deleteText: "",
  originalText: "",
};

const schema = object().shape({
  deleteText: string()
    .required("Delete Text is required")
    .oneOf([ref("originalText"), null], "Website name does not match"),
});

const useDeleteForm = (validate) => {
  const resolver = validate ? yupResolver(schema) : undefined;

  const methods = useForm({
    resolver: resolver,
    defaultValues: defaultValues,
  });

  return methods;
};

export default useDeleteForm;
