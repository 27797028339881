import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFTextField } from "src/components/hook-form";

import axiosInstance from "src/utils/axios";
import * as yup from "yup";

const validatorSchema = yup.object().shape({
  new_email: yup.string().required("New Email is required"),
});

const defaultValues = {
  email: "",
  new_email: "",
};

const EditEmailDialog = ({ email, onClose, reload, open, selectedId }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validatorSchema),
  });

  const onSubmit = async (inputData) => {
    const reqData = new FormData();
    const { new_email } = inputData;
    reqData.append("email", new_email);
    reqData.append("_method", "PUT");

    try {
      const { status, data } = await axiosInstance.post(
        `api/admin/edit-user/${selectedId}`,
        reqData
      );
      if (status === 200) {
        reload();
        onClose();
        enqueueSnackbar(data.message);
        methods.setValue("new_email", "");
      }
    } catch (err) {
      console.log("first");
    }
  };

  useEffect(() => {
    if (email) methods.setValue("email", email);
  }, [email]);
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="change-username"
    >
      <DialogTitle id="change-username">Edit Email</DialogTitle>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                marginTop: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <RHFTextField name="email" label={"Email"} disabled />
              <RHFTextField name="new_email" label={"New Email"} />
            </Box>
          </DialogContentText>

          <DialogActions>
            <Button onClick={onClose} autoFocus color="error">
              Close
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={methods.formState.isSubmitting}
              name="update"
            >
              Update
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};

export default EditEmailDialog;
