export function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/admin";
const ROOTS_USER = "/user";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  page404: "/404",
  page500: "/500",
  components: "/components",
  login: "/login",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
};

export const USER_ROUTES = [
  "/dashboard",
  "/dashboard",
  "/events",
  "/profile",
  "/telegram",
  "/subscriptions",
  "/subscriptions/blogs?type=subscriptions&id=${id}",
  "/subscriptions/view/${id}/${subPath}",
  "/online-store",
  "/online-store/product-subscription",
  "/online-store/product-subscription/checkout",
  "/online-store/product-subscription/${name}",
  "/online-store/my-orders/${id}",
  "/online-store/checkout",
  "/online-store/my-orders",
  "/blogs",
  "/blogs/new",
  "/blogs/${param}",
  "/business-builder",
  "/business-builder/subscriptions",
  "/business-builder/history",
  "/business-builder/materials",
  "/business-builder/materials/${view}",
  "/genealogy",
  "/genealogy/binary",
  "/genealogy/sponsor",
  "/genealogy/affiliate",
  "/genealogy/binaryLeg",
  "/financial",
  "/financial/e-wallet",
  "/financial/deposit-wallet",
  "/financial/funds-transfer",
  "/financial/payout",
  "/financial/coin-address",
  "/help-center",
  "/help-center/tickets",
  "/help-center/faqs",
  "/help-center/mails",
  "/help-center/mails/all",
  "/help-center/create-ticket",
  "/help-center/create-ticket/contact-support",
  "/help-center/create-ticket/other-support",
  "/help-center/create-ticket/my-support-ticket",
  "/help-center/faqs/${view}",
  "/help-center/knowledge-base",
  "/help-center/knowledge-base/${view}",
  "/help-center/mails/${label}",
  "/help-center/create-ticket/${category}",
  "/income-report",
  "/missed-points",
];

export const PATH_DOCS = "";
