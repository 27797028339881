import { useState } from "react";
import useDeleteForm from "./use-delete-form";

const useDeleteHandler = () => {
  const [validate, setValidate] = useState();
  const methods = useDeleteForm(validate);

  const { reset, watch, setValue } = methods;

  const handleCloseDelete = () =>
    reset({
      id: null,
      deleteText: "",
      originalText: "",
      server_data: "",
    });

  const handleOpenDelete =
    ({ id, frontend_url, server_data }) =>
    () => {
      setValue("id", id);
      setValue("originalText", frontend_url);
      setValue("serverData", server_data);
      setValidate(server_data);
    };

  const openDelete = watch("id") !== null;

  return { openDelete, handleOpenDelete, handleCloseDelete, methods, validate };
};

export default useDeleteHandler;
