import Login from "./loginPage/login";
import OtpPage from "./otp/otpPage";
import PageNotFound from "./pageNotfound";
import Progress from "./progressBar/progress";
import Success from "./success/success";

const main = [
  {
    path: "/",
    children: [
      { element: <Login />, index: true },

      {
        path: "otp",
        element: <OtpPage />,
      },
      {
        path: "setUp/:id",
        element: <Progress />,
      },
      {
        path: "success",
        element: <Success />,
      },
      {
        path: "404",
        element: <PageNotFound />,
      },
      {
        path: ":name",
        element: <PageNotFound />,
      },
    ],
  },
];

export default main;
