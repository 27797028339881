import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./pages/routes";
import { SnackbarProvider } from "notistack";
import NotistackProvider from "./components/NotistackProvider";
import MemberContextProvider from "./context/memberContext";

function App() {
  return (
    <div className="App">
      {/* <SnackbarProvider> */}
      <MemberContextProvider>
        <NotistackProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </NotistackProvider>
      </MemberContextProvider>
      {/* </SnackbarProvider> */}
    </div>
  );
}

export default App;
