import AdminLogin from "./admin-login";
import GuestGuard from "./guards/GuestGuard";

const auth = [
  {
    path: "auth",
    element: <GuestGuard />,
    children: [
      {
        path: "login",
        element: <AdminLogin />,
      },
    ],
  },
];

export default auth;
