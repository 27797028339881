import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";
import { PROJECT_NAME } from "src/utils/config";
import { favIcon } from "src/utils/logos";

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => {
  return (
    <>
      <Helmet>
        <title>
          {title} | {PROJECT_NAME}
        </title>
        <link rel="apple-touch-icon" sizes="180x180" href={favIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favIcon} />
        <link rel="icon" type="image/png" sizes="16x16" href={favIcon} />
        <link rel="shortcut icon" href={favIcon} type="image/png/ico" />
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
