import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({
  name,
  sx,
  label,
  placeholder,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          className="loginInput"
          {...field}
          sx={{
            m: 0.4,
            width: 280,
            position: "relative",
            borderRadius: "4px",
            background: "#f1f1f1",
          }}
          fullWidth
          error={!!error}
          helperText={error?.message}
          label={label}
          placeholder={placeholder}
          {...other}
        />
      )}
    />
  );
}
