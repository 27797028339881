import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import { object, string } from "yup";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import Password from "../../components/hook-form/password";
import axiosInstance from "../../utils/axios";
import { PROJECT_NAME } from "../../utils/config";

const setSession = (token) => {
  localStorage.setItem("access-token", token);
};

export const clearSession = () => {
  localStorage.removeItem("access-token");
};

const schema = object().shape({
  email: string().required("Email is required"),
  password: string().required("Password is required"),
});

export const getSession = () => localStorage.getItem("access-token");

export const isLoggedIn = () => getSession() !== null;
const AdminLogin = () => {
  const methods = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const handleSubmit = async (inputData) => {
    const reqData = new FormData();
    Object.entries(inputData).forEach(([k, v]) => reqData.append(k, v));
    try {
      const { data } = await axiosInstance.post("/api/login", reqData);
      const { access_token } = data || {};
      setSession(access_token);
      navigate(0);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Page title="Login" sx={{ height: "100%" }}>
      <Box
        sx={{
          height: "100%",
        }}
      >
        <Container
          fixed
          maxWidth="md"
          sx={{
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper variant="outlined" sx={{ padding: 8 }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4">Hi, Welcome Back!</Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Sign in to {PROJECT_NAME}
                </Typography>
              </Box>
            </Stack>
            <FormProvider
              methods={methods}
              onSubmit={methods.handleSubmit(handleSubmit)}
            >
              <Stack spacing={2}>
                <RHFTextField name="email" label="Email" />
                <Password name="password" label="Password" />

                <LoadingButton
                  loading={methods.formState.isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  login
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Paper>
        </Container>
      </Box>
    </Page>
  );
};

export default AdminLogin;
