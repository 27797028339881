import { enqueueSnackbar } from "notistack";
import { aesEncrypt } from "src/utils/crypto";

const useCopy = () => {
  const onCopy = (id) => () => {
    var encrypted = aesEncrypt(`${id}`);

    try {
      navigator.clipboard.writeText(
        `${window.location.origin}/setUp/${btoa(encrypted)}`
      );
      enqueueSnackbar("Successfully copied to clipboard");
    } catch (err) {
      enqueueSnackbar("Failed to copy", { variant: "error" });
    }
  };

  return onCopy;
};

export default useCopy;
