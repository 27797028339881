import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import axiosInstance from "src/utils/axios";

const DeleteDialog = ({ open, onClose, reload, selectedId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = async () => {
    const reqData = new FormData();
    reqData.append("site_name", "test");

    try {
      const { status, data } = await axiosInstance.post(
        `/api/admin/delete-site/${selectedId}`,
        reqData
      );
      if (status === 200) {
        enqueueSnackbar(data.message);
        reload();
        onClose();
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Delete User</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          Are you sure you want to delete this user? Deleting the user will
          permanently remove all associated data and cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          cancel
        </Button>
        <LoadingButton onClick={handleDelete} variant="contained" color="error">
          delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
