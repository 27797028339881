const headers = [
  "No",
  "Username",
  "Name",
  "Email",
  // "Mobile",
  "API Status",
  // "Country",
  // "City",
  // "Gender",
  "Plan",
  "Date & Time",
  "Verified",
  "Backend URL",
  "Frontend URl",
  "Copy",
  "Action",
];

export default headers;
