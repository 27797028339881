import React, { createContext, useState } from "react";

export const MemberContext = createContext(null);

const MemberContextProvider = ({ children }) => {
  const [memberProfile, setMemberProfile] = useState({});

  return (
    <MemberContext.Provider value={{ memberProfile, setMemberProfile }}>
      {children}
    </MemberContext.Provider>
  );
};
export default MemberContextProvider;
