import { Box } from "@mui/material";
import Page from "src/components/Page";
import DeleteDialog from "./components/delete-dialog";
import useDeleteHandler from "./components/delete-dialog/hooks/use-delete-handler";
import ServerTable from "./components/server-table";
import VerifyDialog from "./components/verify-dialog";
import useVerifyDialog from "./components/verify-dialog/hooks/use-verify-dialog";
import useServer from "./hooks/use-server";
import { useState } from "react";
import EditEmailDialog from "../user/editEmailDialog";
import { PATH_DASHBOARD } from "src/pages/routes/paths";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";

const Server = () => {
  const { state, fetchData, rowStart, page, count, onChange } = useServer();
  const { handleClose, handleOpen, selectedId } = useVerifyDialog();
  const { handleCloseDelete, methods, openDelete, handleOpenDelete, validate } =
    useDeleteHandler();
  const [openChangeEmail, SetChangeEmail] = useState(false);
  const [selectedIdNew, setSelectedIdNew] = useState(null);
  const [email, setEmail] = useState(false);

  const handleOpenEmail = (id, email) => {
    SetChangeEmail(true);
    setSelectedIdNew(id);
    setEmail(email);
  };
  const handleCloseEmail = () => {
    SetChangeEmail(false);
  };
  return (
    <Page title="Server">
      <Box>
        <ServerTable
          page={page}
          count={count}
          onChange={onChange}
          handleOpenDelete={handleOpenDelete}
          handleOpenVerify={handleOpen}
          tableData={state}
          rowStart={rowStart}
          handleOpenEmail={handleOpenEmail}
        />
      </Box>

      <VerifyDialog
        fetchData={fetchData}
        onClose={handleClose}
        selectedId={selectedId}
      />

      <DeleteDialog
        open={openDelete}
        fetchData={fetchData}
        methods={methods}
        onClose={handleCloseDelete}
        validate={validate}
      />
      <EditEmailDialog
        email={email}
        reload={fetchData}
        open={openChangeEmail}
        selectedId={selectedIdNew}
        onClose={handleCloseEmail}
      />
    </Page>
  );
};

export default Server;
