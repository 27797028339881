import { useEffect } from "react";
import useDataHandler from "src/components/data-handler/hooks/use-data-handler";
import usePagination from "src/components/pagination/usePagination";
import axiosInstance from "src/utils/axios";

const useServer = () => {
  const [state, actions] = useDataHandler();
  const { count, onChange, page, seed, rowStart } = usePagination();

  const fetchData = async (page = 1) => {
    actions.loading();
    try {
      const { data } = await axiosInstance.get("/api/admin/users-list", {
        params: { page },
      });
      const { last_page, from, data: list } = data.data || {};
      seed(last_page, from);
      actions.success(list);
    } catch (err) {
      actions.error();
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData(page);
  }, [page]);

  return { state, count, onChange, page, rowStart, fetchData };
};

export default useServer;
