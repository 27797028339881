import { useState } from "react";

const useVerifyDialog = () => {
  const [selectedId, setSelectedId] = useState(null);

  const handleClose = () => setSelectedId(null);
  const handleOpen = (id) => () => setSelectedId(id);

  return { selectedId, handleClose, handleOpen };
};

export default useVerifyDialog;
