import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { aesEncrypt } from "src/utils/crypto";
import * as Yup from "yup";
import axiosInstance from "../../utils/axios";
import ResendOtp from "./ResendOtp";
import Timer from "./Timer";
import MaskedEmail from "./maskedEmail";

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d{7}$/, "OTP must be 4 digits"),
});

const OtpForm = () => {
  const [seconds, setSeconds] = useState(180); // 30 minutes in seconds
  const [resend, setResend] = useState(false);

  const {
    state: { id },
  } = useLocation();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const { otp } = data;
    const reqData = new FormData();
    reqData.append("id", id);
    reqData.append("otp", otp);

    try {
      const { status, data } = await axiosInstance.post(
        `user-verification`,
        reqData
      );
      if (status) {
        enqueueSnackbar(data.message);

        const encrypted = aesEncrypt(`${id}`);
        navigate(`/setUp/${btoa(encrypted)}`);
      }
    } catch (err) {
      if (err.errors) {
        Object.entries(err.errors).forEach(([k, v]) =>
          setError(k, { message: v[0] })
        );
      } else {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    }
  };

  return (
    <div>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: "#485c75", textAlign: "left" }}
        >
          OTP Verification
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray", mt: 1.8 }}>
          Enter the otp send to <MaskedEmail />
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} className="otp-form">
          <div className="otp-form-container">
            <div className="otp-input-container">
              <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                  <OtpInput
                    {...field}
                    numInputs={7}
                    separator={<span>-</span>}
                    isInputNum={true}
                    inputStyle="otp-input-field"
                    renderInput={(props) => (
                      <input {...props} style={{ width: 30 }} />
                    )}
                  />
                )}
              />
            </div>
          </div>
          {errors.otp && (
            <Typography
              variant="subtitle2"
              sx={{ color: "red", textAlign: "left" }}
            >
              {errors.otp.message}
            </Typography>
          )}
          {seconds != 0 ? (
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "gray", textAlign: "left" }}
              >
                This code ends in :{" "}
                <span style={{ color: "black" }}>
                  <Timer seconds={seconds} setSeconds={setSeconds} />
                </span>
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <ResendOtp setResend={setResend} id={id} setSeconds={setSeconds} />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              p: 5,
            }}
          >
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              name="register"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Box>
        </form>

        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="caption" textAlign="center">
            We kindly request your attention to your spam folder as sometimes
            our emails may inadvertently end up there.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default OtpForm;
