import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import useVerifyHandler from "./hooks/use-verify-handler";

const VerifyDialog = ({ selectedId, onClose, fetchData }) => {
  const { handleVerify, loading } = useVerifyHandler(selectedId, {
    fetchData,
    onClose,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={Boolean(selectedId)}
      onClose={onClose}
    >
      <DialogTitle>Verify</DialogTitle>
      <DialogContent>
        <DialogContentText>are you sure you want to verify?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="warning" onClick={onClose}>
          cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleVerify}
          variant="contained"
        >
          verify
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyDialog;
