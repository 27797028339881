import { Box, Button, IconButton, TableCell, TableRow } from "@mui/material";
import Page from "src/components/Page";
import DataHandlerTable from "src/components/data-handler/table";
import Map from "src/components/map";
import useUser from "./hooks/use-user";
import PaginationButtons from "src/components/pagination";
import DeleteDialog from "./DeleteDialog";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import EditEmailDialog from "./editEmailDialog";
import moment from "moment";

const headers = [
  "No",
  "First Name",
  "Last  Name",
  "Username",
  "Email",
  "Mobile",
  "Country",
  "Date & Time",
  "Verified",
  "Action",
  "",
];

const User = () => {
  const { state, fetchData, ...rest } = useUser();
  const { data, ...dataProps } = state;
  const [selectedId, setSelectedId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openChangeEmail, SetChangeEmail] = useState(false);
  const [email, setEmail] = useState(false);

  const handleOpenEmail = (id, email) => {
    SetChangeEmail(true);
    setSelectedId(id);
    setEmail(email);
  };
  const handleCloseEmail = (id) => {
    SetChangeEmail(false);
  };

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setSelectedId(id);
  };
  const closeDelete = () => {
    setOpenDelete(false);
    setSelectedId(null);
  };

  return (
    <Page title="User">
      <Box
        sx={{
          width: "100%",
        }}
      >
        <DataHandlerTable dataProps={dataProps} headers={headers}>
          <Map
            list={data}
            render={(item, i) => {
              const {
                username,
                email,
                verification,
                first_name,
                last_name,
                mobile,
                country,
                id,
                created_at,
              } = item || {};
              return (
                <TableRow>
                  <TableCell>{i + rest.rowStart}</TableCell>
                  <TableCell>{first_name}</TableCell>
                  <TableCell>{last_name}</TableCell>
                  <TableCell>{username}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{mobile}</TableCell>
                  <TableCell>{country}</TableCell>
                  <TableCell>
                    {new Date(created_at).toLocaleDateString("en-GB")}{" "}
                    {moment(created_at).format("LT")}
                  </TableCell>
                  <TableCell>
                    {Boolean(verification) ? verification : "Not Verified"}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{ borderRadius: "6px" }}
                      size="small"
                      variant="contained"
                      onClick={() => handleOpenEmail(id, email)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      size="small"
                      sx={{ borderRadius: "8px" }}
                      variant="outlined"
                      onClick={() => handleOpenDelete(id)}
                    >
                      delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            }}
          />
        </DataHandlerTable>
        <PaginationButtons {...rest} />
      </Box>
      <DeleteDialog
        reload={fetchData}
        open={openDelete}
        selectedId={selectedId}
        onClose={closeDelete}
      />
      <EditEmailDialog
        reload={fetchData}
        open={openChangeEmail}
        selectedId={selectedId}
        onClose={handleCloseEmail}
        email={email}
      />
    </Page>
  );
};

export default User;
