import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import axiosInstance from "src/utils/axios";
import { mainLogo } from "src/utils/logos";

const Success = () => {
  const { state } = useLocation();
  const [data, setData] = useState({
    email: "",
    frontend_url: "",
    password: "",
  });
  const getData = async (id) => {
    try {
      const { data } = await axiosInstance.get("/site-status-response", {
        params: { id },
      });
      const { email, frontend_url, password } = data.data;
      setData({
        email,
        frontend_url,
        password,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (state.id) {
      getData(state.id);
    }
  }, [state.id]);

  const URI = useMemo(() => {
    return `https://${data.frontend_url}`;
  }, [data.frontend_url]);
  return (
    <Page title="Success" sx={{ height: "100%" }}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: "fit-content",
            p: 3,
            pt: 4,
            borderRadius: "15px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ textAlign: "center", mb: -5 }}>
            <img src={mainLogo} width={200} />
          </Box>

          <img src={"/successful-setup.png"} width={550} />

          <Box sx={{ textAlign: "center", p: 2, mt: -8 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "500", color: "#485c75" }}
            >
              You have successfully set up your custom demo!
            </Typography>
            <Typography variant="caption" sx={{ color: "#485c75" }}>
              Go to sign in page and explore your custom demo.
            </Typography>
          </Box>
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 3,
                borderRadius: "15px",
                width: "fit-content",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: "#485c75" }}
                variant="subtitle2"
              >
                Email: {data.email}
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", color: "#485c75" }}
                variant="subtitle2"
              >
                Password: {data.password}
              </Typography>
            </Paper>

            {/* <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                color: "#485c75",
                textAlign: "center",
                p: 1.1,
                mb: 2,
                borderRadius: 8,
                "& a": {
                  // Targeting the <a> tag within Typography
                  textDecoration: "none", // Remove underline
                  color: "#007bff", // Change text color
                  fontWeight: "bold", // Add bold font weight
                  border: "1px solid #007bff", // Add border
                  borderRadius: 4, // Add border radius
                  padding: "0.3rem", // Add padding for better appearance
                  display: "inline-block", // Make the link behave like a block element
                  transition: "background-color 0.3s, color 0.3s", // Add transition for smoother hover effect
                  "&:hover": {
                    // Style on hover
                    backgroundColor: "#007bff", // Change background color on hover
                    color: "#fff", // Change text color on hover
                  },
                },
              }}
            >
              <a href={URI} target="_blank">
                {URI}
              </a>
            </Typography> */}
          </Stack>

          <Stack
            mt={4}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="caption">Go to sign in</Typography>
            <IconButton
              LinkComponent="a"
              href={URI}
              target="_blank"
              sx={{
                backgroundColor: "#318efb",
                "&:hover": {
                  backgroundColor: "#318efb",
                },
              }}
            >
              <Iconify
                icon="ic:round-chevron-right"
                sx={{
                  color: "#fff",
                }}
              />
            </IconButton>
          </Stack>
        </Paper>
      </Box>
    </Page>
  );
};

export default Success;
