import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";

import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import axiosInstance from "src/utils/axios";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password is required")
      .required("Password is required"),
    username: Yup.string().required("username is required"),
    plan: Yup.string().required("Plan is required"),
    repassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "password must match"
    ),
  });
  const defaultValues = {
    email: "",
    username: "",
    mobile: "",
    password: "",
    repassword: "",
    plan: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (inputData) => {
    const reqData = new FormData();
    Object.entries(inputData).forEach(([k, v]) => reqData.append(k, v));

    try {
      const { status, data } = await axiosInstance.post(
        `/api/admin/admin-register`,
        reqData
      );
      if (status) {
        enqueueSnackbar(data.message);
        localStorage.setItem("email", data.data.email);
        localStorage.setItem("id", data.data.id);

        navigate("/admin/server");
      }
    } catch (err) {
      Object.entries(err).forEach(([k, v]) => setError(k, { message: v[0] }));
    }
  });

  return (
    <Page title="Add User">
      <Container maxWidth="sm">
        <Paper
          sx={{
            p: 3,
          }}
        >
          <Box sx={{ ml: -2, mt: 3 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#485c75" }}
            >
              Create Your Custom Demo
            </Typography>
            <Typography variant="caption" sx={{ color: "gray" }}>
              Hello! Sign up to create your custom demo
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", p: 5 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <RHFTextField
                  name="email"
                  label="Enter your email"
                  type="email"
                />
                <RHFTextField name="username" label="Enter username" />
                <RHFTextField name="mobile" label="Enter Mobile" type="tel" />

                <RHFTextField
                  name="password"
                  label="Enter password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFTextField
                  name="repassword"
                  label="Confirm password"
                  type={showRePassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowRePassword(!showRePassword)}
                        >
                          <Iconify
                            icon={
                              showRePassword
                                ? "eva:eye-fill"
                                : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <RHFSelect name="plan" label={"Select a plan"}>
                  <option></option>
                  <option value={"binary"}>Binary</option>
                  <option value={"matrix"}>Matrix</option>
                  <option value={"monoline"}>Mono line</option>
                  <option value={"roi"}>ROI</option>
                  <option value={"unilevel"}>Uni level</option>
                </RHFSelect>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  name="register"
                >
                  Continue
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default LoginForm;
