import DataHandlerTable from "src/components/data-handler/table";
import Map from "src/components/map";
import headers from "./_headers";
import Row from "./components/row";
import useCopy from "./hooks/use-copy";
import PaginationButtons from "src/components/pagination";
import { Box } from "@mui/material";
import Scrollbar from "src/components/Scrollbar";

const ServerTable = ({
  tableData,
  handleOpenDelete,
  handleOpenVerify,
  rowStart,
  count,
  onChange,
  page,
  handleOpenEmail,
}) => {
  const { data, ...dataProps } = tableData;

  const onCopy = useCopy();
  return (
    <div>
      {/* <Scrollbar> */}
      <DataHandlerTable dataProps={dataProps} headers={headers}>
        <Map
          list={data}
          render={(item, i) => {
            return (
              <Row
                handleOpenEmail={handleOpenEmail}
                data={item}
                rowNumber={i + rowStart}
                handleOpenDelete={handleOpenDelete}
                handleOpenVerify={handleOpenVerify}
                onCopy={onCopy}
              />
            );
          }}
        />
      </DataHandlerTable>
      {/* </Scrollbar> */}
      <PaginationButtons count={count} onChange={onChange} page={page} />
    </div>
  );
};

export default ServerTable;
