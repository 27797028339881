import { Button, Stack, TableCell, TableRow } from "@mui/material";
import moment from "moment";

const Row = ({
  data,
  rowNumber,
  onCopy,
  handleOpenDelete,
  handleOpenVerify,
  handleOpenEmail,
}) => {
  const {
    id,
    username,
    email,
    server_data,
    verification,
    gender,
    mobile,
    country,
    created_at,
    first_name,
    last_name,
    city,
  } = data || {};

  const { api_status, backend_url, frontend_url, plan } = server_data || {};

  return (
    <TableRow>
      <TableCell>{rowNumber}</TableCell>
      <TableCell>{username}</TableCell>
      <TableCell>
        {first_name} {last_name}
      </TableCell>
      <TableCell>{email}</TableCell>
      {/* <TableCell>{mobile}</TableCell> */}
      <TableCell>{api_status}</TableCell>
      {/* <TableCell>{country}</TableCell> */}
      {/* <TableCell>{city}</TableCell> */}
      {/* <TableCell>{gender}</TableCell> */}
      <TableCell>{plan}</TableCell>
      <TableCell>
        {new Date(created_at).toLocaleDateString("en-GB")}{" "}
        {moment(created_at).format("LT")}
      </TableCell>
      <TableCell>
        {Boolean(verification) ? verification : "Not Verified"}
      </TableCell>
      <TableCell>{backend_url}</TableCell>
      <TableCell>{frontend_url}</TableCell>
      <TableCell>
        <Button
          sx={{ borderRadius: "6px" }}
          size="small"
          disabled={!backend_url || !frontend_url}
          onClick={onCopy(id)}
        >
          copy
        </Button>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ borderRadius: "6px" }}
            disabled={verification === "verified"}
            onClick={handleOpenVerify(id, server_data)}
            variant="contained"
            size="small"
          >
            {verification === "verified" ? "verified" : "verify"}
          </Button>
          <Button
            sx={{ borderRadius: "6px" }}
            variant="outlined"
            size="small"
            onClick={() => handleOpenEmail(id, email)}
          >
            Edit
          </Button>
          <Button
            color="error"
            sx={{ borderRadius: "6px" }}
            size="small"
            variant="outlined"
            onClick={handleOpenDelete({ id, frontend_url, server_data })}
          >
            delete
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default Row;
