import { useSnackbar } from "notistack";
import axiosInstance from "src/utils/axios";

const useDelete = ({ onClose, fetchData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async (inputData) => {
    const { id, deleteText, serverData } = inputData;
    const reqData = new FormData();
    if (serverData) {
      reqData.append("site_name", deleteText);
    } else {
      reqData.append("site_name", "test");
    }

    try {
      const { data } = await axiosInstance.post(
        `/api/admin/delete-site/${id}`,
        reqData
      );
      fetchData();
      onClose();
      enqueueSnackbar(data.message);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return handleDelete;
};

export default useDelete;
