import { Navigate, useRoutes } from "react-router-dom";
import admin from "../admin";
import auth from "../auth";
import main from "../main";

const Router = () => {
  return useRoutes([
    ...main,
    ...auth,
    ...admin,
    { path: "/*", element: <Navigate to="/404" /> },
  ]);
};

export default Router;
