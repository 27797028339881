import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import useDelete from "./hooks/use-delete";

const DeleteDialog = ({ methods, open, onClose, fetchData, validate }) => {
  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleDelete = useDelete({
    fetchData,
    onClose,
  });
  const originalText = watch("originalText");

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Delete {originalText}</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(handleDelete)}>
        <DialogContent>
          <DialogContentText sx={{ mb: 3 }}>
            {validate
              ? `Please type the name of the website ${originalText} to confirm its deletion.`
              : "Are you sure you want to continue? This cannot be reversed"}
          </DialogContentText>
          {validate && (
            <RHFTextField label="Enter the Name of site." name="deleteText" />
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button variant="outlined" onClick={onClose}>
            cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="error"
          >
            delete
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default DeleteDialog;
