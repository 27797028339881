import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import axiosInstance from "../../utils/axios";
import { set } from "react-hook-form";

const ResendOtp = ({ setResend, id, setSeconds }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleResendOtp = async () => {
    setResend(true);
    setSeconds(180);
    const data = new FormData();
    data.append("id", id);
    try {
      const { status, data: responseData } = await axiosInstance.post(
        `/resend-email`,
        data
      );
      if (status === 200) {
        enqueueSnackbar(responseData.message);
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  return (
    <Box sx={{ mt: 2, textAlign: "left", width: "100%" }}>
      <Typography variant="subtitle2" sx={{ color: "gray" }}>
        Didn't receive a code?
        <Button onClick={handleResendOtp} sx={{ fontWeight: "bold" }}>
          Resend OTP
        </Button>
      </Typography>
    </Box>
  );
};

export default ResendOtp;
