import { useSnackbar } from "notistack";
import { useState } from "react";
import axiosInstance from "src/utils/axios";

const useVerifyHandler = (id, { onClose, fetchData }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleVerify = async () => {
    setLoading(true);
    const reqData = new FormData();
    reqData.append("_method", "PUT");
    try {
      const { data } = await axiosInstance.post(
        `/api/admin/verify-user/${id}`,
        reqData
      );
      setLoading(false);
      fetchData();
      onClose();
      enqueueSnackbar(data.message);
    } catch (err) {
      enqueueSnackbar(err.message);
      setLoading(false);
      console.log(err);
    }
  };

  return { loading, handleVerify };
};
export default useVerifyHandler;
