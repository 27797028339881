import { Box, Stack } from "@mui/material";
import { Navigate, Outlet } from "react-router";
import DesktopMenu from "src/components/menu/desktop";
import MobileMenu from "src/components/menu/mobile";
import Server from "./admin/server";
import User from "./admin/user";
import AddUser from "./admin/user-add";
import { AuthGuard } from "./guards/GuestGuard";
import Dashboard from "./dashBoard";

const Layout = () => {
  return (
    <>
      <Dashboard />
      {/* <DesktopMenu /> */}
      {/* <Stack direction="row">
        <MobileMenu />
        <Box
          sx={{
            alignItems: "center",
            paddingTop: "10vh",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Outlet />
        </Box>
      </Stack> */}
    </>
  );
};

const admin = [
  {
    path: "/admin",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="server" />, index: true },
      {
        path: "server",

        children: [
          { index: true, element: <Server /> },
          { path: "add", element: <AddUser /> },
        ],
      },
      {
        path: "user",
        element: <User />,
      },
    ],
  },
];

export default admin;
