import React from 'react'

const MaskedEmail = () => {
    const email = localStorage.getItem("email")
    const maskEmail = (email) => {
        const atIndex = email.indexOf('@');
        if (atIndex > 1) {
            return email.split('').map((char, index) => {
                if (index < 2 || index >= atIndex) {
                    return char;
                }
                return '*';
            }).join('');
        }
        return email;
    };

    const maskedEmail = maskEmail(email);

    return (
        <span style={{ color: "black" }}>{maskedEmail}</span>
    );
}



export default MaskedEmail