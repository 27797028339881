import * as CryptoJS from "crypto-js";
import { SECRET_KEY } from "src/utils/config";

export const aesEncrypt = (plainText = "") => {
  return CryptoJS.AES.encrypt(plainText, SECRET_KEY).toString();
};

export const aesDecrypt = (encrypted) => {
  return CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
};
