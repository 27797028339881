import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { clearSession, isLoggedIn } from "../admin-login";
import { PATH_DASHBOARD } from "../routes/paths";

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard() {
  if (isLoggedIn()) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export const AuthGuard = ({ children }) => {
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isLoggedIn()) {
    if (pathname !== requestedLocation) {
      clearSession();
      setRequestedLocation(pathname);
    }
    return <Navigate to="/" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};
