import React from "react";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
  ListItemIcon,
  Box,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import ServerIcon from "@mui/icons-material/Storage";
import AddServerIcon from "@mui/icons-material/AddBox";
import LogoutIcon from "@mui/icons-material/Logout";
import Ternary from "src/components/ternary";
import { clearSession } from "src/pages/admin-login";
import webp from "./cloud-logo.webp";

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.action.selected : "inherit",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiListItemIcon-root": {
    color: selected ? theme.palette.primary.main : theme.palette.text.primary,
  },
  "& .MuiListItemText-primary": {
    color: selected ? theme.palette.primary.main : theme.palette.text.primary,
  },
}));

const menuItems = [
  { label: "User", href: "/admin/user", icon: <HomeIcon /> },
  { label: "Server", href: "/admin/server", icon: <ServerIcon /> },
  { label: "Add Server", href: "/admin/server/add", icon: <AddServerIcon /> },
];

const DesktopMenu = ({ usm }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { breakpoints } = useTheme();

  return (
    <Ternary
      when={usm}
      then={
        //     <StyledDrawer variant="permanent" anchor="left">
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.label} disablePadding>
              <StyledListItemButton
                component={Link}
                to={item.href}
                selected={location.pathname === item.href ? 1 : 0}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </StyledListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <StyledListItemButton
              onClick={() => {
                clearSession();
                navigate("/");
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledListItemButton>
          </ListItem>
        </List>
        // </StyledDrawer>
      }
    />
  );
};

export default DesktopMenu;
