import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import { aesDecrypt } from "src/utils/crypto";
import { mainLogo } from "src/utils/logos";
import axiosInstance from "../../utils/axios";
import backend_site from "../images/Backend git Installing.png";
import backend_deployement from "../images/Deploying Back-end.png";
import frontend_deployement from "../images/Deploying.png";
import frontend_site_repository from "../images/Frontend git Installing.png";
import fakedata from "../images/Inserting fake data.png";
import registed from "../images/Registering User.png";
import database from "../images/Setting up the database.png";
import backend_env_status from "../images/Updating Back-end ENV.png";
import frontend_env_status from "../images/Updating front-end ENV.png";

const Progress = () => {
  const { id: encryptedID } = useParams();
  const navigate = useNavigate();

  const id = useMemo(() => {
    return aesDecrypt(atob(encryptedID));
  }, [encryptedID]);

  const [dataProgress, setDataProgress] = useState({});
  const lengthOfData = Object.keys(dataProgress).length;
  const countInstalled = Object.values(dataProgress).filter(
    (value) => value === "installed"
  )?.length;

  const installedKeys = Object.keys(dataProgress).filter(
    (key) => dataProgress[key] === "installed"
  );
  installedKeys?.sort((a, b) => a.localeCompare(b));
  const latestInstalledKey = installedKeys[installedKeys?.length - 1];

  const total = (countInstalled / lengthOfData) * 100;

  const fetchData = async () => {
    try {
      const { status, data: responseData } = await axiosInstance.get(
        `/site-status?id=${id}`
      );
      if (status === 200) {
        setDataProgress(responseData.data);
      }
    } catch (err) {
      //   enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchData();
    if (lengthOfData === countInstalled) {
      return;
    } else {
      const intervalId = setInterval(fetchData, 5000);
      return () => clearInterval(intervalId);
    }
  }, [lengthOfData, countInstalled]);

  useEffect(() => {
    if (total === 100) {
      navigate("/success", { state: { id } });
    }
  });

  const getLabel = (latestInstalledKey) => {
    switch (latestInstalledKey) {
      case "backend_deployement":
        return backend_deployement;
      case "backend_env_status":
        return backend_env_status;
      case "backend_site":
        return backend_site;
      case "database":
        return database;
      case "frontend_deployement":
        return frontend_deployement;
      case "frontend_env_status":
        return frontend_env_status;
      case "frontend_site":
        return fakedata;
      case "frontend_site_repository":
        return frontend_site_repository;
      case "backend_site_repository":
        return registed;
      default:
        return frontend_deployement;
    }
  };
  const getText = (latestInstalledKey) => {
    switch (latestInstalledKey) {
      case "backend_deployement":
        return "Setting up and making the backend of a website...";
      case "backend_env_status":
        return "Environment setting and configuration for backend of a system...";
      case "backend_site":
        return "Developing the technical framework and functionality...";
      case "database":
        return "Setting up the database ,essentially creating the storage space...";
      case "frontend_deployement":
        return "Configuring and preparing the user interface";
      case "frontend_env_status":
        return "Configuration of the environment for the frontend";
      case "frontend_site":
        return "Signifies the process of designing ,developing and constructing user interface";
      case "frontend_site_repository":
        return "Configuring the storage space and necessary settings for managing the source code";
      case "backend_site_repository":
        return "Setting up a backend site repository involves creating a central storage space for all the code and files related to the backend part of your web application";
      default:
        return frontend_deployement;
    }
  };
  return (
    <Page title="Set up">
      <Box
        className="box"
        sx={{ borderRadius: "15px", overflow: "hidden", p: 4 }}
      >
        <Box sx={{ textAlign: "center", mt: 6, mb: 2 }}>
          <img src={mainLogo} width={120} />
        </Box>
        <Box sx={{ textAlign: "center", p: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#485c75" }}
          >
            Please Wait...
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "gray" }}>
            We are setting up your custom demo{" "}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <img src={getLabel(latestInstalledKey)} width={300} height={260} />
        </Box>
        <Box sx={{ textAlign: "center", p: 3 }}>
          <Typography variant="subtitle1" sx={{ color: "#485c75" }}>
            {countInstalled === 0 ? "Waiting...." : getText(latestInstalledKey)}
          </Typography>
        </Box>
        <Box sx={{ width: "30%", ml: "auto", mr: "auto", mb: 5 }}>
          <LinearProgress value={total} variant="determinate" />
        </Box>
      </Box>
    </Page>
  );
};

export default Progress;
