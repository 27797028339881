import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Page from "src/components/Page";
import { mainLogo } from "src/utils/logos";
import mlm from "../images/mlm theme copy.png";
import LoginForm from "./loginForm";
import "./style.css";

const Login = () => {
  return (
    <Page title="Join">
      <Box className="box" sx={{ borderRadius: "15px", overflow: "hidden" }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: "left", ml: 8, mt: 10 }}>
              <img src={mainLogo} width={150} />
            </Box>
            <Box>
              <LoginForm />
            </Box>
          </Grid>

          <Grid
            sx={{
              p: 10,
              backgroundColor: "#348efe",
              textAlign: "left",
              display: { md: "block", xs: "none" },
            }}
            xs={6}
          >
            <Box>
              <Typography
                sx={{
                  ml: -2,
                  fontSize: "30px",
                  fontWeight: "Bold",
                  color: "white",
                  mb: 4,
                }}
              >
                The Ultimate MLM Software
              </Typography>

              <ul
                style={{
                  listStyleType: "square",
                  textAlign: "left",
                  color: "#fff",
                  paddingLeft: "0",
                  lineHeight: "35px",
                }}
              >
                <li>Wide range of MLM plans</li>
                <li>Responsive MLM dashboards</li>
                <li>Instant pay-ins and payouts</li>
                <li>Strong Backup System</li>
              </ul>
            </Box>
            <Box sx={{ justifyContent: "center", display: "flex", mt: 5 }}>
              <img src={mlm} width={"135%"} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Login;
